import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { Parallax } from 'react-scroll-parallax';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import {
  getActiveDocLanguage,
  getFormattedEvents,
  getSiteMeta,
  prefersReducedMotion,
} from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import ParkEntrance from '../components/ParkEntrance';
import HomepageHero from '../components/HomepageHero';
import HomepageVision from '../components/HomepageVision';
import FeaturedEventCallout from '../components/FeaturedEventCallout';
import NewsEventsCallout from '../components/NewsEventsCallout';
import AnimateFadeIn from '../components/AnimateFadeIn';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText);
}

const HomepageVisit = ({ content: { visit_title, entrances } }) => {
  const headingRef = useRef([]);

  useEffect(() => {
    gsap.from(headingRef.current, {
      x: -40,
      opacity: 0,
      duration: 0.65,
      stagger: 0.07,
      scrollTrigger: {
        trigger: headingRef.current,
        start: 'top 90%',
      },
      ease: 'cubic.inOut',
    });
  }, []);

  const VisitStacked = visit_title.split('\n').map((text, i) => (
    <React.Fragment key={text}>
      <Col base={12} sm_md={5} xl={4} sm_mdOffset={i === 0 ? 7 : 8}>
        <Heading
          level="h2"
          size="l"
          content={text}
          ref={el => (headingRef.current[i] = el)}
        />
      </Col>
    </React.Fragment>
  ));

  return (
    <section>
      <VisitTitle>
        <Row>{VisitStacked}</Row>
      </VisitTitle>

      {entrances.map((entrance, i) => (
        <ParkEntrance
          content={entrance}
          key={entrance.name}
          variant={i === 0 ? 'left' : 'right'}
        />
      ))}
    </section>
  );
};

const Homepage = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const entrances = data.allPrismicParkEntrances.edges[0].node.data.entrance;
  const news = data.allPrismicNews;
  const events = data.allPrismicEvent;

  // Get page data
  const homepage = data.prismicHomepage;
  const {
    hero_slideshow,
    visit_title,
    intro,
    overview,
    sections,
    display_featured_event,
    featured_event,
  } = homepage.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(homepage);
  const defaultMeta = getSiteMeta(layout.data);

  const headingRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline();
    const splitText = new SplitText(headingRef.current, { type: 'lines' });

    tl.current
      .to(headingRef.current, {
        autoAlpha: 1,
        delay: 0.25,
      })
      .from(
        splitText.lines,
        {
          y: 40,
          duration: 0.6,
          stagger: 0.1,
          ease: 'cubic.inOut',
        },
        '<'
      );

    return () => {
      tl.current.kill();
    };
  }, []);

  const formattedEvent = display_featured_event
    ? getFormattedEvents([{ node: featured_event.document }], activeDoc.locale)
    : null;

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} hideOverflowX>
      <Seo lang={activeDoc.locale} defaultMeta={defaultMeta} isHome />

      <AnimateFadeIn>
        <HeroTitle>
          <Row>
            <Col base={12} xs={10} sm={6} lg={5} smOffset={1}>
              <Parallax
                y={[-80, 40]}
                disabled={prefersReducedMotion}
                className="hero-title"
              >
                <Heading
                  level="h1"
                  size="xl"
                  content={defaultMeta.site_title}
                  ref={headingRef}
                />
              </Parallax>
            </Col>
          </Row>
        </HeroTitle>

        <Parallax y={[-10, 10]}>
          <HomepageHero content={hero_slideshow} lang={activeDoc.locale} />
        </Parallax>

        <HomepageVisit content={{ visit_title, entrances }} />

        <HomepageVision content={{ intro, overview, sections }} />

        {display_featured_event && formattedEvent && (
          <FeaturedEventCallout
            content={formattedEvent[0]}
            lang={activeDoc.locale}
          />
        )}

        <NewsEventsCallout
          news={news}
          events={events}
          lang={activeDoc.locale}
        />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Homepage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const HeroTitle = styled.div`
  margin-top: 0;
  z-index: 1;
  position: relative;

  &:lang(en) {
    z-index: 0;
  }

  h1 {
    opacity: 0;
    visibility: hidden;
  }
`;

const VisitTitle = styled.div`
  margin-bottom: var(--space-l);
  text-align: center;

  @media (${bp.min.sm_md}) {
    margin-bottom: -1.2rem;
    text-align: left;
  }

  @media (${bp.min.lg}) {
    margin-bottom: -4rem;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicHomepage(lang: { eq: $language }) {
      _previewable
      data {
        title
        visit_title
        display_featured_event
        featured_event {
          document {
            ... on PrismicEvent {
              ...FeaturedEvent
            }
          }
        }
      }
      ...HomepageHero
      ...HomepageVision
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    allPrismicParkEntrances(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...ParkEntrance
        }
      }
    }
    allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...EventItems
        }
      }
    }
  }
`;
